function isBase64(input) {
    try {
        window.atob(input)
        return true
    } catch (error) {
        return false
    }
}

export const validateOpenSSHRsaPublicKey = publicKey => {
    if (!publicKey) {
        return false
    }

    const [part1, part2] = publicKey.trim().split(' ')

    if (part1 !== 'ssh-rsa') {
        return false
    }

    if (!isBase64(part2)) {
        return false
    }

    return true
}

export const isURL = url => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$',
        'i'
    )

    return pattern.test(url)
}
